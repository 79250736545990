// *******************************************************************************
// Open/Close Modal
// *******************************************************************************

import { attachEvent, toggleBodyScrollLock } from '../../../../js/default';
import handleForm from '../../../../js/form-validation';
import { request } from '../../../../js/api';

const popupBody = document.querySelector('.notification-pop-up__container');

if (popupBody) {
	const form = popupBody.querySelector('.notification-pop-up__form-inner');
	const inputElem = document.querySelector('#notification_pop_up_email');
	const submitBtn = form.querySelector('.btn');
	const productId = document.querySelector('.product-module__title')?.id;
	const notificationError = popupBody.querySelector('.notification-pop-up__form-error');
	const notificationThankyou = document.querySelector('.notification-pop-up__thankyou');
	const fv = handleForm(form);
	const dropdown = document.querySelector('.dropdown__placeholder-text-inner');
	let formValues;

	const handleSendForm = async () => {
		if (inputElem) {
			formValues = {
				[inputElem.name]: inputElem.value,
			};
		}

		if (dropdown) {
			const dropdownValue = document.querySelector('.dropdown__list-item > input:checked');
			formValues = {
				[dropdownValue.name]: dropdownValue.value,
			};
		}

		try {
			await request(`${window.location.origin}/api/v1/notification`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
				body: JSON.stringify({
					...formValues,
					id: productId,
					model: 'product',
				}),
			});

			notificationThankyou.classList.toggle('hide', false);
			popupBody.classList.toggle('hide', true);
		} catch (error) {
			notificationError.classList.toggle('hide', false);
			const errorText = notificationError.querySelector('.notification-pop-up__form-error-text');
			errorText.textContent = `${error}`.replace('Error: ', '');
		}
	};

	submitBtn.addEventListener('click', async (ev) => {
		ev.preventDefault();
		if (inputElem) {
			fv.validate();
			fv.on('core.form.valid', async () => {
				await handleSendForm();
			});
		}

		if (dropdown) {
			await handleSendForm();
		}
	});

	const toggleNotificationPopup = (ele, toggle) => {
		ele?.classList.toggle('is-open-modal', toggle);
		toggleBodyScrollLock(toggle);
	};

	const closeNotificationPopups = () => {
		document.querySelectorAll('.notification-pop-up').forEach((el) => toggleNotificationPopup(el, false));
	};

	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			closeNotificationPopups();
		}
	});

	attachEvent('.notification-pop-up__overlay, .notification-pop-up__close', 'click', (ev) => {
		toggleNotificationPopup(ev.target.closest('.notification-pop-up'), false);

		// Reset input after window closes
		setTimeout(() => {
			if (inputElem) {
				inputElem.value = '';
				form.querySelector('.mod-input').classList.remove('is-validation-failed', 'is-focused');
				fv.resetField('email');
			}
			notificationError.classList.toggle('hide', true);
			notificationThankyou.classList.toggle('hide', true);
			popupBody.classList.toggle('hide', false);
		}, 300);

		ev.preventDefault();
	});

	// **************************************************
	// Open modal
	// **************************************************

	attachEvent('.notification-pop-up-trigger', 'click', (ev) => {
		const popUpEle = ev.target.closest('section, .notification-pop-up-parent')?.querySelector('.notification-pop-up');
		if (popUpEle) {
			toggleNotificationPopup(popUpEle, true);
		}
		ev.preventDefault();
	});
}
