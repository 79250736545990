// **********************************************************
// Comparison Product Popup
// **********************************************************

import Cookies from 'js-cookie';
// eslint-disable-next-line import/extensions
import { request } from '../../../../js/api.js';
import { attachEvent, toggleBodyScrollLock } from '../../../../js/default';

const setTextOnButton = (addRemove) => {
	document.querySelector('.compare-product-popup-trigger span').innerHTML = addRemove ?  'PRIMERJAJ IZDELEK' : 'ODSTRANI IZ PRIMERJALNIKA';
}

const getItemsFromCookie = () => JSON.parse(decodeURIComponent(Cookies.get('compare_items') || '[]'));

const deleteSecureCookie = (cookieName) => {
	document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure`;
};

const setItemToCookie = async (id, productBox = '') => {
	if (!id) {
		return;
	}
	const currentData = getItemsFromCookie();
	const index = currentData.indexOf(id);
	const products = document.querySelectorAll('.compare-product-popup__product');
	const otherLinks = document.querySelectorAll('.compare-product-popup__product-list a');
	const alertPopup = document.querySelector('.alert-popup');
	if (index === -1 && products && productBox) {
		const popupBox = products[0];
		if (popupBox) {
			const toggle = productBox.dataset.category !== popupBox.dataset.category;
			alertPopup.classList.toggle('is-open-modal', toggle)
			toggleBodyScrollLock(toggle);
			if (toggle) {
				return;
			}
		}
	}
	if (currentData.length > 2 && index === -1) {
		alertPopup.querySelector('p').innerHTML =
			'V primerjalniku lahko imate največ 3 izdelke iz iste kategorije, ' +
			'zato v primerjalnik nismo mogli dodati izdelka. ' +
			'Želeni izdelek lahko dodate tako, da iz primerjalnika, ' +
			'ki je levo spodaj odstranite en izdelek.';
		alertPopup.classList.toggle('is-open-modal', true)
		toggleBodyScrollLock(true);
		return;
	}
	if (index !== -1) {
		currentData.splice(index, 1);
	} else {
		currentData.push(id);
	}
	if (currentData.length > 0) {
		Cookies.set('compare_items', JSON.stringify(currentData), {
			expires: 31,
			secure: true
		});
	} else {
		deleteSecureCookie('compare_items');
	}

	const result = await request(`${window.location.origin}/api/v1/compare-products`, {
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
		body: JSON.stringify({ products: currentData }),
	});
	products.forEach((item) => {
		item.remove();
	});
	otherLinks.forEach((item) => {
		item.href = result.category;
	});
	const compareProductPopup = document.querySelector('.compare-product-popup');
	if (compareProductPopup) {
		compareProductPopup.classList.add('is-open');
	}
	document.querySelector('.compare-product-popup__product-list').insertAdjacentHTML('afterbegin', result.html);
	removeProduct();
};


// ---
// Active Popup
// ---

attachEvent('.compare-product-popup-trigger', 'click', (ev) => {
	ev.preventDefault();
	if (ev.target.closest('.product-card__box')) {
		setItemToCookie(
			Number(ev.target.closest('.product-card__box').dataset.id || 0),
			ev.target.closest('.product-card__box')
		)
		ev.target.blur();
	}
});

// ---
// Open Popup
// ---

attachEvent('.compare-product-popup__comparator-btn', 'click', (ev) => {
	const parentEle = ev.target.closest('.compare-product-popup');
	parentEle.classList.toggle('is-open');
});

// ---
// Remove Product
// ---

const removeProduct = () => {
	const parentSection = document.querySelector('.compare-product-popup');
	if (!parentSection) {
		return;
	}
	const productBox = document.querySelector('.product-summary');
	if (productBox) {
		const currentData = getItemsFromCookie();
		const index = currentData.indexOf(Number(productBox.dataset.id));
		setTextOnButton(index === -1);
	}

	const productItems = parentSection.querySelectorAll('.compare-product-popup__product');
	if (productItems.length === 0) {
		parentSection.classList.remove('is-open', 'is-active');
	}
	document.querySelector('.sc-compare-product-popup').classList.toggle('is-active', productItems.length);
	const addItemButton = parentSection.querySelector('.compare-product-popup__add-item');
	const compareButton = parentSection.querySelector('.compare-product-popup__compare-button');
	compareButton.classList.toggle('d-none', productItems.length < 2);

	if (addItemButton) {
		const addThirdItemText = addItemButton.querySelector('.compare-product-popup__add-item-text-third');
		const addAnotherItemText = addItemButton.querySelector('.compare-product-popup__add-item-text-another');

		addItemButton.classList.toggle('d-none', productItems.length >= 3);
		addAnotherItemText.classList.toggle('d-none', productItems.length === 2);
		addThirdItemText.classList.toggle('d-none', productItems.length < 2);
	}
};

window.addEventListener('DOMContentLoaded', removeProduct);

attachEvent('.compare-product-popup__product-remove', 'click', (ev) => {
	if (ev.target.closest('.compare-product-popup__product')) {
		setItemToCookie(
			Number(ev.target.closest('.compare-product-popup__product').dataset.id || 0)
		);
	}
});
