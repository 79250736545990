import { attachEvent, euro, parseCurrencyToNumber, scrollToTop, slideToggle } from '../../../../js/default';
import { checkSearchParams, removeSearchParams, updateList, updateSearchParams } from '../../../../js/api';
import initializeSlider from '../range-slider/range-slider';

let productFilter = document.querySelector('.product-filter');
if (productFilter) {
	const selectionRows = document.querySelectorAll('.product-filter__selections-row');
	const productFilterSelections = document.querySelectorAll('.product-filter__selections');
	const urlString = 'api/v1/filters/products';

	let productTotalText = document.querySelector('.product-list__information:not(.d-md-none)');
	let rangeSlider = document.querySelector('#range-slider-input');
	let productFilterInputs = document.querySelectorAll('input[type=checkbox]');
	let priceFilterInputs = productFilter.querySelectorAll('input[type=radio]');
	let priceRangeFilter = document.querySelectorAll('input[name="price_slider_radio"]');
	let container = document.querySelector('.product-list__container');

	const getCategoryIdQuery = () => {
		let query = `id=${container.getAttribute('data-category-id')}`;
		if (container.getAttribute('data-category-id') === 'special_actions') {
			query = `${query}&actionId=${container.getAttribute('data-action-id')}`;
		}

		return query;
	};

	let categoryIdQuery = getCategoryIdQuery();

	const enableDisable = (show) => {
		if (!show) {
			scrollToTop(container);
		}

		productFilter.classList.toggle('is-disabled__no-opacity', show);
		container.classList.toggle('is-disabled', show);
	};

	const toggleProductFilterClass = (toggle) => {
		document.body.classList.toggle('is-open-product-filter', toggle);
	};

	const toggleProductSortClass = (toggle) => {
		document.body.classList.toggle('is-open-sort-dropdown', toggle);
	};

	const updateUI = async (callback, params) => {
		enableDisable(true);

		const data = await callback(...params);

		// TODO update filters
		// productFilter.outerHTML = data?.filters;
		container.innerHTML = data?.section;
		productTotalText.innerHTML = data?.total_text;

		// initializeSlider();
		// initializeFilters();

		enableDisable(false);
	};

	const addSelection = (inputId, inputText, params = null) => {
		const htmlString = `
                <div class="product-filter__selections-item" data-product="${inputId}">
                    <span class="product-filter__selections-text">${inputText}</span>
                    <button aria-label="remove ${inputText}" class="product-filter__selections-close">
                        <i class="icon : icon-close : blue : p-none"></i>
                    </button>
                </div>
            `;

		selectionRows.forEach((row) => {
			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = htmlString.trim();
			const existingDiv = row.querySelector(`[data-product="${inputId}"]`);
			if (existingDiv) {
				row.replaceChild(tempDiv.firstChild, existingDiv);
			} else {
				row.appendChild(tempDiv.firstChild);
			}
		});

		productFilterSelections.forEach((el) => {
			el.classList.toggle('hide', false);
		});

		if (params) {
			updateUI(updateSearchParams, [params.value, params.action, params.key, urlString, categoryIdQuery]);
		}
	};

	const parseMinMax = (value) => {
		const min = parseCurrencyToNumber('sl-SI', 'EUR', value[0]);
		const max = parseCurrencyToNumber('sl-SI', 'EUR', value[1]);

		const minCurrency = euro.format(min);
		const maxCurrency = euro.format(max);

		return [minCurrency, maxCurrency];
	};

	const initializeFilters = () => {
		productFilter = document.querySelector('.product-filter');
		if (productFilter) {
			productTotalText = document.querySelector('.product-list__information:not(.d-md-none)');
			rangeSlider = document.querySelector('#range-slider-input');
			productFilterInputs = document.querySelectorAll('input[type=checkbox]');
			priceFilterInputs = productFilter.querySelectorAll('input[type=radio]');
			priceRangeFilter = document.querySelectorAll('input[name="price_slider_radio"]');
			container = document.querySelector('.product-list__container');
			categoryIdQuery = getCategoryIdQuery();

			if (productFilter.classList.contains('is-disabled')) {
				enableDisable(false);
			}

			// Min max filter slider
			if (rangeSlider) {
				rangeSlider?.noUiSlider?.on('change', () => {
					priceRangeFilter.forEach((item) => {
						item.checked = false;
					});

					const rangeValue = rangeSlider?.noUiSlider?.get();
					const [min, max] = parseMinMax(rangeSlider?.noUiSlider?.get());

					addSelection('minmax', `${min} - ${max}`, {
						value: `${rangeValue[0]}:${rangeValue[1]}`,
						action: 'replace',
						key: 'minmax',
					});
				});
			}

			// Min max radio buttons
			priceRangeFilter.forEach((input) => {
				input.addEventListener('change', (ev) => {
					const parentElem = ev.target.closest('.product-filter__item-content-inner');
					parentElem.querySelectorAll(`input[type=radio]:not([id=${ev.target.id}])`).forEach((element) => {
						element.checked = false;
					});

					rangeSlider.noUiSlider.set([input.dataset.min, input.dataset.max]);
					const [min, max] = parseMinMax(rangeSlider.noUiSlider.get());

					addSelection('minmax', `${min} - ${max}`, {
						value: `${input.dataset.min}:${input.dataset.max}`,
						action: 'replace',
						key: 'minmax',
					});
				});
			});

			// Filter
			productFilterInputs.forEach((input) => {
				input.addEventListener('change', (el) => {
					const parentElem = el.target.closest('.checkbox');
					const inputText = parentElem?.querySelector('.checkbox__label-text').textContent;
					const inputId = el.target.getAttribute('id');

					if (input.type !== 'radio' && !input.checked) {
						const filterCards = document.querySelectorAll(`.product-filter__selections-item[data-product="${inputId}"]`);
						filterCards.forEach((item) => {
							item.remove();
						});

						const params = [String(inputId), 'remove', 'filters', urlString, categoryIdQuery];
						updateUI(updateSearchParams, params);

						const checkedInputs = document.querySelectorAll('.product-filter__selections-item');
						productFilterSelections.forEach((el) => {
							el.classList.toggle('hide', checkedInputs.length === 0);
						});
						return;
					}

					addSelection(inputId, inputText, { value: inputId, action: 'add', key: 'filters' });
				});
			});

			container.addEventListener('click', (event) => {
				if (event.target.closest('button.pagination__link')) {
					const pageNumber = event.target.closest('button.pagination__link').getAttribute('data-url');
					updateUI(updateSearchParams, [pageNumber, 'replace', 'stran', urlString, categoryIdQuery]);
				}

				if (event.target.closest('button.show-more-button')) {
					const pageNumber = event.target.closest('button.show-more-button').getAttribute('data-url');
					updateUI(updateSearchParams, [pageNumber, 'replace', 'stran', urlString, categoryIdQuery]);
				}
			});
		}
	};

	// Open/Close filter on mobile
	attachEvent('[data-product-filter-trigger]', 'click', (ev) => {
		toggleProductFilterClass(!document.body.classList.contains('is-open-product-filter'));
		ev.preventDefault();
	});

	// Open/Close filter on mobile
	attachEvent('[data-product-sort-trigger]', 'click', (ev) => {
		toggleProductSortClass(!document.body.classList.contains('is-open-sort-dropdown'));
		ev.preventDefault();
	});

	// Close filter on mobile
	attachEvent('[data-close-product-filter]', 'click', (ev) => {
		toggleProductFilterClass(false);
		ev.preventDefault();
	});

	attachEvent('[data-close-product-sort], .product-list__sort .dropdown__list-option', 'click', (ev) => {
		if (ev.target.dataset.closeProductSort !== '') {
			updateUI(updateSearchParams, [ev.target.getAttribute('data-value'), 'replace', 'sort', urlString, categoryIdQuery]);
		}
		toggleProductSortClass(false);
	});

	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			toggleProductFilterClass(false);
			toggleProductSortClass(false);
		}
	});

	// Open/Close filter accordion - Desktop
	attachEvent('.product-filter__item-title', 'click', (ev) => {
		const parent = ev.target.closest('.product-filter__item');
		const content = parent.querySelector('.product-filter__item-content');

		parent.classList.toggle('is-active');
		slideToggle(content, 300, 'block');
		ev.preventDefault();
	});

	attachEvent('.product-filter__selections-close', 'click', (ev) => {
		const parent = ev.target.closest('.product-filter__selections-item');
		const productId = parent.getAttribute('data-product');
		document.querySelectorAll(`.product-filter__selections-item[data-product="${productId}"]`).forEach((item) => {
			item.remove();
		});

		let params;
		const targetInput = document.getElementById(productId !== 'minmax' ? productId : 'range-slider-input');

		targetInput.checked = false;
		if (targetInput.type === 'checkbox') {
			params = [String(productId), 'remove', 'filters', urlString, categoryIdQuery];
		} else {
			const rangeValue = rangeSlider.noUiSlider.get();
			params = [
				`${rangeValue[0].replace(',', '.')}:${rangeValue[1].replace(',', '.')}`,
				'remove',
				productId,
				urlString,
				categoryIdQuery,
			];
			const { min, max } = rangeSlider.noUiSlider.options.range;
			rangeSlider.noUiSlider.set([String(min), String(max)]);
			priceFilterInputs.forEach((element) => {
				element.checked = false;
			});
		}
		updateUI(updateSearchParams, params);

		const checkedInputs = document.querySelectorAll('.product-filter__selections-item');
		productFilterSelections.forEach((el) => {
			el.classList.toggle('hide', checkedInputs.length === 0);
		});

		ev.preventDefault();
	});

	// Reset Filter
	attachEvent('.product-filter__reset', 'click', (ev) => {
		removeSearchParams();
		productFilterSelections.forEach((el) => {
			el.classList.toggle('hide', true);
		});
		selectionRows.forEach((row) => {
			row.innerHTML = '';
		});
		productFilterInputs.forEach((input) => {
			input.checked = false;
		});
		priceFilterInputs.forEach((input) => {
			input.checked = false;
		});
		const { min, max } = rangeSlider.noUiSlider.options.range;
		rangeSlider.noUiSlider.set([String(min), String(max)]);

		ev.preventDefault();
		updateUI(updateList, [urlString, categoryIdQuery]);
	});

	window.addEventListener('DOMContentLoaded', () => {
		initializeSlider();
		initializeFilters();

		const filterParams = checkSearchParams('filters');
		const minmaxParams = checkSearchParams('minmax');

		if (filterParams) {
			filterParams?.split(',').forEach((item) => {
				const element = document.getElementById(`${item}`);
				const parentElem = element.closest('.checkbox');
				const inputText = parentElem?.querySelector('.checkbox__label-text').textContent;

				addSelection(item, inputText, { value: item, action: 'add', key: 'filters' });
			});
		}

		if (minmaxParams) {
			const [minParam, maxParam] = minmaxParams.split(':');

			rangeSlider.noUiSlider.set([Number(minParam), Number(maxParam)]);
			const [min, max] = parseMinMax(rangeSlider?.noUiSlider?.get());
			addSelection('minmax', `${min} - ${max}`, {
				value: minmaxParams,
				action: 'replace',
				key: 'minmax',
			});

			const radioInput = productFilter.querySelector(
				`input[type=radio][data-min="${Math.trunc(Number(minParam))}"][data-max="${Math.trunc(Number(maxParam))}"]`,
			);

			if (radioInput) {
				radioInput.checked = true;
			}
		}
	});
}
