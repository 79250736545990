import handleForm from './form-validation';
import { request } from './api';
import { initTagCollectionsSlider } from '../views/partials/modules/tag_collections/tag_collections';
import { initializeNewsletterForm } from '../views/partials/molecules/newsletter/newsletter';
import { euro, parseCurrencyToNumber, scrollToElement, slideDown } from './default';

window.addEventListener('DOMContentLoaded', () => {
	const formBody = document.querySelector('.checkout-module');
	const formData = {};

	if (formBody) {
		let activeForm;
		let form;
		let fv;
		let submitBtn;

		// VALIDATE ON CLICK
		const handleClick = (ev) => {
			ev.preventDefault();
			fv.validate();
		};

		const postcodeValidator = (primary, secondary, key, value_key) => {
			primary.addEventListener('blur', async (ev) => {
				if (ev.target.value !== '') {
					const response = await request(`${window.location.origin}/api/v1/post-codes?${key}=${primary.value}`);
					primary.setAttribute('data-error', !!response?.error);
					if (!response.error) {
						secondary.setAttribute('data-error', !!response?.error);
					}

					if (!response?.error) {
						secondary.closest('.mod-input').classList.toggle('is-focused', true);
						secondary.value = response[value_key];
					}

					fv.validateField(primary.name);
					if (secondary.value !== '') {
						fv.validateField(secondary.name);
					}
				}
			});
		};

		const checkPostcode = (postNoName, placeName) => {
			const postNoField = form.querySelector(`[name=${postNoName}]`);
			const placeField = form.querySelector(`[name=${placeName}]`);

			fv.addField(postNoName, {
				validators: {
					validatePostcode: {
						message: postNoField?.dataset?.invalidErrorMessage || '',
					},
				},
			});

			fv.addField(placeName, {
				validators: {
					validatePostcode: {
						message: placeField?.dataset?.invalidErrorMessage || '',
					},
				},
			});

			if (postNoField && placeField) {
				postcodeValidator(postNoField, placeField, 'post_id', 'title');
				postcodeValidator(placeField, postNoField, 'post_name', 'post_id');
			}
		};

		// INITIAL FORM
		const initializeForm = () => {
			activeForm = document.querySelector('.is-active');
			form = activeForm.querySelector(
				`.${activeForm.querySelector('.checkout-module__step-fields > div').getAttribute('class')}__fields`,
			);
			submitBtn = formBody.querySelector('.checkout-module__step-footer > button');

			fv = handleForm(form);
			fv.on('core.form.valid', handleValidForm);

			if (fv.fields.postna) {
				checkPostcode('postna', 'kraj');
			}

			if (fv.fields.drzava) {
				fv.addField('drzava', {
					validators: {
						validateCountry: {},
					},
				});
			}

			if (fv.fields.tax_number) {
				fv.addField('tax_number', {
					validators: {
						validateVat: {},
					},
				});
			}

			if (fv.fields.telefonska) {
				fv.addField('telefonska', {
					validators: {
						validatePhone: {},
					},
				});
			}

			submitBtn.addEventListener('click', handleClick);
		};

		// UPDATE FORM STEP
		const updateStep = (container) => {
			form = container.querySelector(`.${container.querySelector('.checkout-module__step-fields > div').classList[0]}__fields`);
			const containerId = container.querySelector('.checkout-module__step-fields > div').classList[0];
			if (['payment-methods', 'checkbox-group'].includes(containerId)) {
				window.dataLayer = window.dataLayer || [];

				const valueText = document.querySelector('.cart-summary__footer-col.text-right');
				const valueNumber = parseCurrencyToNumber('sl-SI', 'EUR', valueText.textContent);
				const products = document.querySelectorAll('.product-preview');
				let itemsArray = [];
				products?.forEach((product) => {
					const exists = itemsArray.find((item) => product.dataset.id === item.item_id);

					if (!exists) {
						const rawData = document.getElementById(`product-ld-data-${product.dataset.id}`);
						const parsedData = JSON.parse(rawData.textContent);

						let count = 0;
						products.forEach((item) => {
							if (item.dataset.id === parsedData.item_id) {
								count += 1;
							}
						});

						itemsArray = [
							...itemsArray,
							{
								item_id: parsedData.item_id,
								item_name: parsedData.item_name,
								discount: parsedData.discount,
								item_brand: parsedData.item_brand,
								item_category: parsedData.item_category,
								item_category2: parsedData.item_category2,
								item_variant: parsedData?.variation,
								price: parseCurrencyToNumber('sl-SI', 'EUR', parsedData.price),
								quantity: count,
							},
						];
					}
				});

				dataLayer.push({
					event: containerId === 'payment-methods' ? 'add_shipping_info' : 'add_payment_info',
					ecommerce: {
						currency: 'EUR',
						value: valueNumber,
						items: itemsArray,
						coupon: formData.promo_code_input || null,
						...(containerId === 'checkbox-group' && { payment_method: formData.payment_method }),
					},
				});
			}

			if (form) {
				fv = handleForm(form);
				fv.on('core.form.valid', handleValidForm);

				if (fv.fields.other_address_postcode) {
					checkPostcode('other_address_postcode', 'other_address_place');
				}

				if (fv.fields.postna) {
					checkPostcode('postna', 'kraj');
				}

				if (fv.fields.drzava) {
					fv.addField('drzava', {
						validators: {
							validateCountry: {},
						},
					});
				}

				if (fv.fields.tax_number) {
					fv.addField('tax_number', {
						validators: {
							validateVat: {},
						},
					});
				}

				if (fv.fields.payment_tax_number) {
					fv.addField('payment_tax_number', {
						validators: {
							validateVat: {},
						},
					});
				}

				if (fv.fields.telefonska) {
					fv.addField('telefonska', {
						validators: {
							validatePhone: {},
						},
					});
				}

				submitBtn.removeEventListener('click', handleClick);
				submitBtn = container.querySelector('.checkout-module__step-footer > button');
				submitBtn.addEventListener('click', handleClick);
			}
		};

		const updateFormData = (field, key, element) => {
			switch (element.type) {
				case 'radio':
					const parentElem = element.closest('[data-radio-parent]');
					const selectedRadioButton = parentElem.querySelector('input:checked');
					if (selectedRadioButton) {
						const { value } = selectedRadioButton;
						if (key === 'payment_method') {
							formData[key] = selectedRadioButton.id;
						} else if (key === 'installment_payment_sub') {
							if (formData.payment_method === 'installment_payment') {
								formData[key] = {
									type: selectedRadioButton.id,
									installmentCount: element.dataset.installmentCount || null,
									installmentArray: element.dataset.installmentArray || null,
								};
							}
						} else if (key !== 'drzava') {
							formData[key] = true;
						} else {
							formData[key] = value;
						}

						if (field) {
							field.textContent = value;
						}
					}
					break;
				case 'checkbox':
					if (element.checked) {
						if (key === 'apartment_delivery' || key === 'delivery_removal_old_product') {
							formData[key] = [];
						} else if (key.startsWith('apartment_delivery') && formData.apartment_delivery) {
							formData.apartment_delivery.push(element.value);
						} else if (key.startsWith('delivery_removal_old_product') && formData.delivery_removal_old_product) {
							formData.delivery_removal_old_product.push(element.value);
						} else {
							formData[key] = true;
						}

						if (field) {
							field.textContent = element.value;
						}
					} else {
						if (field) {
							field.textContent = '';
						}
					}
					break;
				case 'text':
					formData[key] = isNaN(element.value) ? element.value : parseFloat(element.value);
					break;
				case 'textarea':
				case 'email':
				case 'tel':
					formData[key] = element.value;
					break;
				default:
					break;
			}
		};

		const updateInformation = (field, key, element = null, value = null) => {
			if (field) {
				switch (key) {
					case 'kraj':
						field.textContent = `${formData.postna} ${element.value}`;
						break;
					case 'other_address_place':
						field.textContent = `${formData.other_address_postcode} ${element.value}`;
						break;
					case 'installment_payment_sub':
						if (formData.payment_method !== 'installment_payment') {
							field.textContent = document.querySelector('.payment-methods__list input:checked').value;
							return;
						}

						const container = element.closest('[data-radio-parent]');
						field.textContent = container.querySelector('input:not(.is-disabled):checked').value;
						break;
					case 'ece_bonus':
						field.innerHTML = `Vrednost ECE bonusa: <br><strong class="text--blue">${euro.format(value)}</strong>`;
						break;
					case 'promo_code':
						field.textContent = value;
						break;
					default:
						if (!['radio', 'checkbox'].includes(element.type)) {
							field.textContent = element.value;
						}
						break;
				}

				if (field.closest('.information-box__item').classList.contains('hide')) {
					field.closest('.information-box__item').classList.toggle('hide', false);
				}
			}
		};

		const parseFormData = () => {
			Object.keys(fv.getFields()).forEach((key) => {
				const element = form.querySelector(`[data-fv-name="${key}"]:not(.is-disabled)`);
				if (!element || element.value === '') {
					return;
				}

				const fieldKey = key !== 'installment_payment_sub' ? key : 'payment_method';
				const field = document.querySelector(`.information-box__item-${fieldKey}`);
				if (formData[key]) {
					delete formData[key];
				}

				updateFormData(field, key, element);
				updateInformation(field, key, element);
			});
		};

		// HANDLE VALID STEP SUBMISSION
		const handleValidForm = async () => {
			parseFormData();

			// UPDATE DOM
			const infoBox = activeForm.querySelector('.checkout-module__step-overview');
			const nextStep = document.querySelector('.checkout-module__step:not(.is-active):not(.is-completed)');

			fv.destroy();
			document.querySelectorAll('.fv-plugins-message-container:not(.hide)').forEach((element) => {
				element.remove();
			});

			if (nextStep) {
				activeForm.classList.toggle('is-active', false);
				activeForm.classList.toggle('is-completed', true);

				infoBox.style.display = 'block';
				nextStep.classList.toggle('is-active', true);
				slideDown(nextStep.querySelector('.checkout-module__step-body'), 500);
				scrollToElement(nextStep, 'checkout');

				// REMOVE OVERVIEW BOX
				nextStep.querySelector('.checkout-module__step-overview').display = 'none';
				activeForm = nextStep;

				updateStep(nextStep);
			} else {
				const body = document.querySelector('body');
				body.classList.toggle('is-disabled', true);
				body.classList.toggle('scroll-disabled', true);

				const response = await request(`${window.location.origin}/api/v1/checkout/`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(formData),
				});

				if (Object.keys(response).length > 0) {
					if (response.isCardPayment) {
						window.location.href = response.redirectUrl;
					}

					if (response.thank_you) {
						const thankyouContainer = document.querySelector('body');
						thankyouContainer.innerHTML = `${response.thank_you}`;
						document.querySelector('.cart__btn-number').classList.toggle('hide');
						document.querySelector('.cart__btn-text').textContent = euro.format(0);

						document.cookie = 'cart_hash=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure';
						window.dataLayer = window.dataLayer || [];

						const valueText = document.querySelector('.cart-summary__footer-col.text-right');
						const valueNumber = parseCurrencyToNumber('sl-SI', 'EUR', valueText.textContent);
						const products = document.querySelectorAll('.product-preview');
						let itemsArray = [];
						products?.forEach((product) => {
							const exists = itemsArray.find((item) => product.dataset.id === item.item_id);

							if (!exists) {
								const rawData = document.getElementById(`product-ld-data-${product.dataset.id}`);
								const parsedData = JSON.parse(rawData.textContent);

								let count = 0;
								products.forEach((item) => {
									if (item.dataset.id === parsedData.item_id) {
										count += 1;
									}
								});

								itemsArray = [
									...itemsArray,
									{
										item_id: parsedData.item_id,
										item_name: parsedData.item_name,
										discount: parsedData.discount,
										item_brand: parsedData.item_brand,
										item_category: parsedData.item_category,
										item_category2: parsedData.item_category2,
										item_variant: parsedData?.variation,
										price: parseCurrencyToNumber('sl-SI', 'EUR', parsedData.price),
										quantity: count,
									},
								];
							}
						});

						dataLayer.push({
							event: 'purchase',
							ecommerce: {
								currency: 'EUR',
								value: valueNumber,
								items: itemsArray,
								tax: '',
								shipping: '',
								coupon: formData.promo_code_input || null,
							},
						});
					}

					if (response.error_module) {
						const errorContainer = document.querySelector('.checkout-module');
						errorContainer.innerHTML = `${response.error_module}`;
					}

					if (!response.isCardPayment) {
						setTimeout(() => {
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}, 100);
					}

					initTagCollectionsSlider();
					initializeNewsletterForm();
				}

				body.classList.toggle('is-disabled', false);
				body.classList.toggle('scroll-disabled', false);
			}
		};

		// HANDLE CHANGE INFO
		document.querySelectorAll('.information-box__link').forEach((btn) => {
			btn.addEventListener('click', (e) => {
				e.preventDefault();
				const container = btn.closest('.checkout-module__step-overview');
				const formContainer = btn.closest('.is-completed');

				container.style.display = 'none';
				activeForm.classList.toggle('is-active', false);
				formContainer.classList.toggle('is-active', true);
				formContainer.classList.toggle('is-completed', false);

				slideDown(formContainer.querySelector('.checkout-module__step-body'), 500);
				scrollToElement(formContainer, 'checkout');

				activeForm = formContainer;
				updateStep(formContainer);
			});
		});

		initializeForm();

		const handleOptionChange = async (ev) => {
			const { value, name, checked, dataset } = ev.target;
			const priceDisplay = ev.target.closest('.delivery-methods__list-item').querySelector('.delivery-methods__option-price > p');
			const currentValue = parseCurrencyToNumber('sl-SI', 'EUR', priceDisplay.textContent);
			const elementValue = parseFloat(dataset.additionalValue);

			const newCartSummary = await request(`${window.location.origin}/api/v1/modify-removal-delivery-product`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					product: Number(value),
					type: name.includes('_delivery_') ? 'delivery' : 'removal',
					operation: checked ? 'add' : 'remove',
				}),
			});

			if (newCartSummary.success) {
				document.querySelector('.cart-summary').outerHTML = newCartSummary.checkout_sidebar;
			}

			if (elementValue && typeof currentValue === 'number') {
				const newCost = currentValue + (checked ? elementValue : -elementValue);
				priceDisplay.textContent = euro.format(newCost);
				ev.target
					.closest('.delivery-methods__list-item')
					.querySelector('.delivery-methods__option-price')
					.classList.toggle('hidden', !checked);
			}
		};

		const handleDeliveryChange = async (ev) => {
			if (!ev.target.checked) {
				const deliveryListItem = ev.target.closest('.delivery-methods__list-item');
				const checkboxes = deliveryListItem.querySelectorAll('.delivery-methods__sub-item > .delivery-methods__option > input');
				const priceDisplay = deliveryListItem.querySelector('.delivery-methods__option-price > p');
				let newCost = parseCurrencyToNumber('sl-SI', 'EUR', priceDisplay.textContent);

				checkboxes.forEach((checkbox) => {
					if (checkbox.checked) {
						const elementValue = parseFloat(checkbox.dataset.additionalValue);
						newCost -= elementValue;
						checkbox.checked = false;
					}
				});

				const newCartSummary = await request(`${window.location.origin}/api/v1/delete-removal-delivery-product`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						type: ev.target.name.includes('_delivery') ? 'delivery' : 'removal',
					}),
				});

				if (newCartSummary.success && parseCurrencyToNumber('sl-SI', 'EUR', priceDisplay.textContent) !== newCost) {
					document.querySelector('.cart-summary').outerHTML = newCartSummary.checkout_sidebar;
					priceDisplay.textContent = euro.format(newCost);
				}
			}
		};

		const inputElements = document.querySelectorAll('.delivery-methods__sub-item > .delivery-methods__option > input');
		inputElements.forEach((element) => {
			element.addEventListener('change', handleOptionChange);
		});

		const deliveryMethodHeadings = document.querySelectorAll('.delivery-methods__heading input');
		deliveryMethodHeadings.forEach((heading) => {
			heading.addEventListener('change', handleDeliveryChange);
		});

		const hiddenInputsToggle = document.querySelectorAll('.toggle-hidden-inputs input:first-child');
		hiddenInputsToggle.forEach((toggleInput) => {
			toggleInput.addEventListener('change', () => {
				if (toggleInput.checked) {
					return;
				}

				const selectedInputs = toggleInput
					.closest('.toggle-hidden-inputs')
					.querySelectorAll('.toggle-hidden-inputs__list input, .toggle-hidden-inputs__list textarea');

				selectedInputs.forEach((hiddenInput) => {
					const field = document.querySelector(`.information-box__item-${hiddenInput.name}`);
					delete formData[hiddenInput.name];
					if (field) {
						field?.closest('.information-box__item')?.classList.toggle('hide', true);
						field.textContent = '';
					}
					hiddenInput.value = '';
				});
			});
		});

		const handleAddCouponOrBonus = async (couponInput, payerId = 0) => {
			const bodyJson = {
				coupon_code: couponInput.value || 'ece_bonus',
				type: payerId ? 'ece_bonus' : 'coupon',
			};

			if (payerId) {
				bodyJson.payer_id = payerId;
			}

			const response = await request(`${window.location.origin}/api/v1/add-coupon`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},

				body: JSON.stringify(bodyJson),
			});

			const promoCodeOrBonusInput = couponInput.closest('.promo-code-input, .redeem-bonus');
			const messageContainer = promoCodeOrBonusInput.querySelector('.fv-plugins-message-container');
			promoCodeOrBonusInput.classList.remove('is-success');
			// messageContainer.innerHTML = '';
			if (!response.success) {
				messageContainer.innerHTML = response.message;
				messageContainer.classList.toggle('hide', false);
			} else {
				messageContainer.classList.toggle('hide', true);
				promoCodeOrBonusInput.classList.add('is-success');
				document.querySelector('.promo-code-input__form-inner').style.pointerEvents = 'none';
				document.querySelectorAll(payerId ? '.promo-code-input.bonus-boxes' : '.redeem-bonus.bonus-boxes').forEach((item) => {
					item.classList.add('is-confirmed');
				});
			}
			if (response.checkout_sidebar) {
				document.querySelector('.checkout-module__sidebar').innerHTML = response.checkout_sidebar;
				const field = document.querySelector(`.information-box__item-${payerId ? 'ece_bonus' : 'promo_code'}`);

				let bonusValue = null;
				if (payerId) {
					const payerChoice = document.querySelector('.dropdown__placeholder-text');
					bonusValue = payerChoice.dataset.ehrm.split('_')[0] || null;
				}
				updateInformation(field, payerId ? 'ece_bonus' : 'promo_code', null, payerId ? bonusValue : couponInput.value);
			}
		};

		const handleRemoveCoupon = async () => {
			const response = await request(`${window.location.origin}/api/v1/remove-coupon`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (response.success) {
				document.querySelector('.promo-code-input.is-success, .redeem-bonus.is-success').classList.remove('is-success');
				const inputElement = document.getElementById('promo_code_input');
				inputElement.value = '';
				inputElement.closest('.mod-input').classList.remove('is-focused');
				document.querySelector('.promo-code-input__form-inner').style.pointerEvents = '';
			}
			if (response.checkout_sidebar) {
				document.querySelector('.checkout-module__sidebar').innerHTML = response.checkout_sidebar;
				['ece_bonus', 'promo_code'].forEach((key) => {
					const field = document.querySelector(`.information-box__item-${key}`);
					field.closest('.information-box__item').classList.toggle('hide', true);
				});
			}
			document.querySelectorAll('.delivery-and-benefits__fields .bonus-boxes').forEach((item) => {
				item.classList.remove('is-confirmed');
			});
		};
		document.querySelectorAll('.promo-code-input__submit-button').forEach((button) => {
			button.addEventListener('click', async (ev) => {
				ev.preventDefault();
				const couponInput = button.closest('.promo-code-input__form').querySelector('#promo_code_input');
				if (couponInput) {
					await handleAddCouponOrBonus(couponInput);
				}
			});
		});
		document.querySelectorAll('.redeem-bonus__submit-button').forEach((button) => {
			button.addEventListener('click', async (ev) => {
				ev.preventDefault();
				const payerID = Number(button.closest('.redeem-bonus__inner').querySelector('#payer').value);
				await handleAddCouponOrBonus(button, payerID);
			});
		});
		document.querySelectorAll('.promo-code-input__remove-button, .redeem-bonus__remove-button').forEach((button) => {
			button.addEventListener('click', async (ev) => {
				ev.preventDefault();
				await handleRemoveCoupon();
			});
		});

		const updateInstallments = async (id) => {
			const installmentPaymentSelected = !!document.querySelector('.cart-summary__installment');

			if (id === 'installment_payment' || installmentPaymentSelected) {
				const installmentPaymentValue = document.querySelector('.payment-methods__option input[id="installment_payment"]').checked;
				const newCartSummary = await request(`${window.location.origin}/api/v1/modify-installment`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						type: installmentPaymentValue,
					}),
				});

				if (newCartSummary.success) {
					if (newCartSummary.installment_data) {
						document.querySelectorAll('.payment-methods__radio-group input').forEach((element) => {
							element.setAttribute('data-installment-count', newCartSummary.installment_data.installments_num);
							element.setAttribute('data-installment-array', newCartSummary.installment_data.installments_input);
						});
					}
					document.querySelector('.cart-summary').outerHTML = newCartSummary.checkout_sidebar;
				}
			}
		};

		const payerDropdown = document.querySelector('#payer_select.dropdown__list-inner');
		if (payerDropdown) {
			const [, payer_id] = document.querySelector('.dropdown__placeholder-text').dataset.ehrm.split('_');
			formData.payer_id = payer_id;

			payerDropdown.addEventListener('click', () => {
				const payerChoice = activeForm.querySelector('.dropdown__placeholder-text');
				const [bonusValue, payerId] = payerChoice.dataset.ehrm.split('_');
				const payerData = JSON.parse(payerChoice.dataset.payer);

				Object.keys(payerData).forEach((key) => {
					const formInput = activeForm.querySelector(`input[name=${key}]`);
					if (formInput) {
						const label = formInput.closest('.mod-input');
						label.classList.toggle('is-focused', payerData[key]);
						formInput.value = payerData[key];
					}
				});

				if (document.querySelector('.redeem-bonus.is-success')) {
					handleRemoveCoupon();
				}

				if (bonusValue) {
					formBody.querySelector('.redeem-bonus__inner').classList.toggle('is-disabled', bonusValue === '0');
					formBody.querySelector('.redeem-bonus__total-bonus').textContent = euro.format(parseFloat(bonusValue));
					formBody.querySelector('input[name="payer-id"]').value = payerId;
					formData.payer_id = payerId;
				}

				if (!bonusValue && formData.payer_id) {
					delete formData.payer_id;
				}

				const installmentInput = document.getElementById('installment_payment');
				const inputContainer = installmentInput.closest('.payment-methods__list-item');

				if (payerChoice.dataset.installments === 'true') {
					inputContainer.classList.toggle('is-disabled', false);
					installmentInput.removeAttribute('disabled');
				} else {
					inputContainer.classList.toggle('is-disabled', true);
					installmentInput.setAttribute('disabled', '');

					if (installmentInput.checked) {
						const stepContainer = installmentInput.closest('.checkout-module__step ');

						if (stepContainer && stepContainer.classList.contains('is-completed')) {
							stepContainer.classList.toggle('is-completed', false);
							stepContainer.querySelector('.checkout-module__step-overview').style.display = 'none';
						}
						installmentInput.checked = false;
						inputContainer.querySelector('.payment-methods__list-item-content').classList.add('hide');
						document.querySelector('.payment-methods__option input[id="pre_invoice"]').checked = true;

						inputContainer.querySelectorAll('input').forEach((inputElem) => {
							if (inputElem.checked) {
								inputElem.checked = false;
							}

							if (inputElem.type === 'text') {
								inputElem.value = null;
							}
						});
						updateInstallments('pre_invoice');
					}
				}
			});
		}

		const installmentPayment = document.querySelectorAll('.payment-methods__option input');
		if (installmentPayment) {
			installmentPayment.forEach((element) => {
				element.addEventListener('change', async (ev) => {
					await updateInstallments(ev.target.id);
				});
			});
		}
	}
});

// TODO: handle error and success messages, with JS or with landing pages?
if (window.location.hash) {
	const hash = window.location.hash.substring(1);
	if (hash === 'error') {
		// TODO: show error screen
	} else if (hash === 'success') {
		// TODO: show success screen
	}
}
