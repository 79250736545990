// **********************************************************
// Hero Module
// **********************************************************

import Swiper from 'swiper/bundle';

const heroModule = document.querySelectorAll('[data-slider="hero-slider"]');

const changeColor = (currentSlide) => {
	[
		{
			query: '.title',
			className: 'text--white',
		},
		{
			query: '.hero-module__slide-text',
			className: 'hero-module__slide-text-white',
		},
		{
			query: '.btn',
			className: 'btn--hero-white',
		},
	].forEach(({ query, className }) => {
		currentSlide?.querySelector(query)?.classList.toggle(className, !!currentSlide?.dataset?.color);
	});

	const previousBtn = document.querySelector('.swiper-button-prev');
	const nextBtn = document.querySelector('.swiper-button-next');
	previousBtn?.classList.toggle('btn--hero-white', !!currentSlide?.dataset?.color);
	nextBtn?.classList.toggle('btn--hero-white', !!currentSlide?.dataset?.color);
	document.activeElement?.blur();

	const progressBar = document.querySelector('.swiper-pagination-progressbar-fill');
	progressBar?.classList.toggle('slider--white', !!currentSlide?.dataset.color);

	const progressCount = document.querySelector('.swiper-custom-pagination');
	progressCount?.classList.toggle('hero-module__slide-text-white', !!currentSlide?.dataset.color);
};

const makeRedirect = (element) => {
	element.addEventListener('click', () => {
		window.location = element.dataset.link;
	});
};

const heroModuleOptions = {
	slidesPerView: 1,
	spaceBetween: 0,
	speed: 800,
	autoHeight: true,
	watchOverflow: true,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: '.swiper-progressbar',
		type: 'progressbar',
	},
	on: {
		init() {
			const currentSlide = this.slides[this.activeIndex];
			if (currentSlide.dataset.link) {
				makeRedirect(currentSlide);
			}
			const progressBar = document.querySelector('.swiper-pagination-progressbar-fill');
			progressBar?.classList.toggle('slider--white', !!currentSlide?.dataset.color);
		},
		slideChange() {
			const currentSlide = this.slides[this.activeIndex];
			changeColor(currentSlide);
			const paginationEle = currentSlide.closest('section').querySelector('.swiper-custom-pagination__current-slide');

			if (currentSlide.dataset.link) {
				makeRedirect(currentSlide);
			}

			paginationEle.innerHTML = this.realIndex + 1;
		},
	},
	breakpoints: {
		1023: {
			autoHeight: false,
		},
	},
};

heroModule.forEach((el) => {
	const heroModuleEle = el.closest('.hero-module');
	const arrowRight = heroModuleEle.querySelector('.swiper-button-next');
	const arrowLeft = heroModuleEle.querySelector('.swiper-button-prev');
	const progressBarElement = heroModuleEle.querySelector('.swiper-progressbar');

	heroModuleOptions.navigation.nextEl = arrowRight;
	heroModuleOptions.navigation.prevEl = arrowLeft;
	heroModuleOptions.pagination.el = progressBarElement;

	new Swiper(el, heroModuleOptions);
});
