// **********************************************************
// Hero Module
// **********************************************************

import Swiper from 'swiper';
import { windowWidth, enableScrollbarSync } from '../../../../js/default';

const relatedArticlesSlider = document.querySelectorAll('[data-slider="related-articles"]');

const relatedArticlesOptions = {
	slidesPerView: 'auto',
	spaceBetween: 20,
	watchOverflow: true,
	speed: 800,
	scrollbar: {
		el: '.swiper-scrollbar',
		hide: false,
		draggable: true,
	},
	breakpoints: {
		767: {
			freeMode: true,
			spaceBetween: 30,
		},
	},
};

const initRelatedArticlesSlider = () => {
	relatedArticlesSlider.forEach((el) => {
		const parentElem = el.closest('.blog-detail');
		const scrollbarEle = parentElem.querySelector('.swiper-scrollbar');

		relatedArticlesOptions.scrollbar.el = scrollbarEle;

		if (windowWidth() > 767) {
			new Swiper(el, relatedArticlesOptions);
		} else {
			enableScrollbarSync(parentElem);
		}
	});
};

initRelatedArticlesSlider();

window.addEventListener('resize', () => {
	relatedArticlesSlider.forEach((el) => {
		const parentElem = el.closest('.blog-detail');

		if (windowWidth() > 767) {
			if (!el.swiper) {
				const swiperEle = new Swiper(el, relatedArticlesOptions);
				swiperEle.update();
			}
		} else {
			enableScrollbarSync(parentElem);

			if (el.swiper) {
				el.swiper.destroy();
			}
		}
	});
});
