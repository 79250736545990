/* eslint no-param-reassign: "error" */
/* eslint-disable no-new */

// *******************************************************************************
// VARIOUS FUNCTIONS
// *******************************************************************************

// Return Window Width
export const windowWidth = () => window.innerWidth || document.documentElement.clientWidth;

// *******************************************************************************
// FUNCTIONS
// *******************************************************************************

// Attach Events
export const attachEvent = (selector, event, handler) => {
	document.addEventListener(
		event,
		(ev) => {
			let { target } = ev;
			for (; target && target !== document; target = target.parentNode) {
				if (target.matches(selector)) {
					try {
						handler.call(target, ev);
					} catch (e) {
						console.error(e);
					}
					break;
				}
			}
		},
		false,
	);
};

// *******************************************************************************
// DEBOUNCE FUNCTION
// *******************************************************************************

export const debounce = (func, wait, immediate) => {
	let timeout;
	return (...args) => {
		const context = this;
		const later = () => {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

// *******************************************************************************
// SLIDE FUNCTIONS
// *******************************************************************************

export const slideUp = (target, duration) => {
	target.style.transitionProperty = 'height, margin, padding';
	target.style.transitionDuration = `${duration}ms`;
	target.style.boxSizing = 'border-box';
	target.style.height = `${target.offsetHeight}px`;
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight;
	target.style.overflow = 'hidden';
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	window.setTimeout(() => {
		target.style.display = 'none';
		target.style.removeProperty('height');
		target.style.removeProperty('padding-top');
		target.style.removeProperty('padding-bottom');
		target.style.removeProperty('margin-top');
		target.style.removeProperty('margin-bottom');
		target.style.removeProperty('overflow');
		target.style.removeProperty('transition-duration');
		target.style.removeProperty('transition-property');
	}, duration);
};

export const slideDown = (target, duration, displayType = null, defaultHeight = null) => {
	target.style.removeProperty('display');

	let { display } = window.getComputedStyle(target);

	if (display === 'none') {
		display = displayType;
	}
	target.style.display = display;

	const height = target.offsetHeight;
	target.style.overflow = 'hidden';
	target.style.height = defaultHeight ? `${defaultHeight}px` : 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight;
	target.style.boxSizing = 'border-box';
	target.style.transitionProperty = 'height, margin, padding';
	target.style.transitionDuration = `${duration}ms`;
	target.style.height = `${height}px`;
	target.style.removeProperty('padding-top');
	target.style.removeProperty('padding-bottom');
	target.style.removeProperty('margin-top');
	target.style.removeProperty('margin-bottom');
	window.setTimeout(() => {
		target.style.removeProperty('height');
		target.style.removeProperty('overflow');
		target.style.removeProperty('transition-duration');
		target.style.removeProperty('transition-property');
	}, duration);
};

export const slideToggle = (target, duration, displayType) => {
	/* Slide-toggle logic */
	if (window.getComputedStyle(target).display === 'none') {
		return slideDown(target, duration, displayType);
	}

	return slideUp(target, duration, displayType);
};

// *******************************************************************************
// ADD SCROLLBAR WIDTH
// *******************************************************************************

const getScrollbarWidth = () => {
	const outer = document.createElement('div');
	outer.style.visibility = 'hidden';
	outer.style.overflow = 'scroll'; // forcing scrollbar to appear
	outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
	document.body.appendChild(outer);
	const inner = document.createElement('div');
	outer.appendChild(inner);
	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
	outer.parentNode.removeChild(outer);
	document.documentElement.style.setProperty('--scroll-width', `${scrollbarWidth}px`);
};

getScrollbarWidth();

window.addEventListener('resize', () => {
	getScrollbarWidth();
});

export const toggleBodyScrollLock = (toggle) => {
	document.body.classList.toggle('body-scroll-locked', toggle);
};

export const toggleCategoriesClass = (toggle) => {
	document.body.classList.toggle('is-open-categories', toggle);
};

export const toggleCartClass = (toggle) => {
	document.body.classList.toggle('is-open-cart', toggle);
};

export const toggleMenuClass = (toggle) => {
	document.body.classList.toggle('is-open-menu', toggle);
};

export const toggleSearchClass = (toggle, searchInput) => {
	document.body.classList.toggle('is-open-search', toggle);
	toggleBodyScrollLock(toggle);

	if (toggle) {
		searchInput.focus();
	} else {
		searchInput.blur();
	}
};

export const toggleActiveClass = (element, toggle) => {
	element.classList.toggle('is-active', toggle);
};

export const removeActiveClass = (items) => {
	items.forEach((link) => {
		link.classList.remove('is-active');
	});
};

export const toggleConfigurationPopup = (ele, toggle) => {
	ele?.classList.toggle('is-open-modal', toggle);
	toggleBodyScrollLock(toggle);
};

export const scrollToTop = (element) => {
	if (element) {
		window.scrollTo({
			top: element.getBoundingClientRect().top + window.scrollY - 420,
			behavior: 'smooth',
		});
	}
};

export const scrollToElement = (element, type = 'site') => {
	const offset = element.getBoundingClientRect().top + window.scrollY;
	const headerElem = document.querySelector(`.${type}-header`);

	window.scrollTo({
		top: offset - headerElem.getBoundingClientRect().height - 20,
		behavior: 'smooth',
	});
};

// enableScrollbarSync for Slider In Mobile
export const enableScrollbarSync = (parent, scrollbarEle) => {
	const swiperWrapper = parent.querySelector('.swiper');
	const swiperInner = parent.querySelector('.swiper-wrapper');
	const scrollbar = scrollbarEle || parent.querySelector('.swiper-scrollbar');
	scrollbar.innerHTML = '<div class="swiper-scrollbar-thumb"></div>';
	const scrollbarThumb = scrollbar.querySelector('.swiper-scrollbar-thumb');

	let startX;
	let startThumbLeft;

	const onTouchMove = (event) => {
		const deltaX = event.touches[0].clientX - startX;
		const thumbLeft = Math.min(Math.max(startThumbLeft + deltaX, 0), scrollbar.offsetWidth - scrollbarThumb.offsetWidth);
		const scrollLeft =
			(thumbLeft / (scrollbar.offsetWidth - scrollbarThumb.offsetWidth)) * (swiperWrapper.scrollWidth - swiperWrapper.clientWidth);
		swiperWrapper.scrollLeft = scrollLeft;
	};

	const onTouchEnd = () => {
		document.removeEventListener('touchmove', onTouchMove);
		document.removeEventListener('touchend', onTouchEnd);
	};

	const onTouchStart = (event) => {
		startX = event.touches[0].clientX;
		startThumbLeft = scrollbarThumb.offsetLeft;

		document.addEventListener('touchmove', onTouchMove);
		document.addEventListener('touchend', onTouchEnd);
	};

	const onScroll = () => {
		const scrollPercentage = (swiperWrapper.scrollLeft / (swiperWrapper.scrollWidth - swiperWrapper.clientWidth)) * 100;
		const maxThumbWidth = scrollbar.offsetWidth - scrollbarThumb.offsetWidth;
		const thumbPosition = (scrollPercentage / 100) * maxThumbWidth;
		scrollbarThumb.style.left = `${thumbPosition}px`;
	};

	scrollbarThumb.addEventListener('touchstart', onTouchStart);
	swiperWrapper.addEventListener('scroll', onScroll);

	if (swiperInner.clientWidth < scrollbar.clientWidth) {
		scrollbar.classList.add('hide');
	}

	return () => {
		scrollbarThumb.removeEventListener('touchstart', onTouchStart);
		swiperWrapper.removeEventListener('scroll', onScroll);
	};
};

export const euro = Intl.NumberFormat('sl-SI', {
	style: 'currency',
	currency: 'EUR',
	useGrouping: false,
});

export const parseCurrencyToNumber = (lang, currency, value) => {
	const separatorDecimal = new Intl.NumberFormat(lang, {
		style: 'decimal',
	})
		.format(11.11)
		.replace(/\d/g, '');

	const separatorThousands = new Intl.NumberFormat(lang, {
		style: 'decimal',
	})
		.format(1111)
		.replace(/\d/g, '');

	const symbolOnLeft = new Intl.NumberFormat(lang, {
		style: 'currency',
		currency,
	})
		.format(1)
		.replace(new RegExp(`\\d|[${separatorDecimal}${separatorThousands}]*`, 'g'), '');

	const stringNumber = value
		.replace(new RegExp(`[${separatorThousands}]`, 'g'), '')
		.replace(separatorDecimal, '.')
		.replace(new RegExp(`[${symbolOnLeft}]`, 'g'), '');

	return parseFloat(stringNumber);
};

export const disableButton = async (element, callback) => {
	element.style.opacity = 0.5;
	element.style.pointerEvents = 'none';

	await callback();

	element.style.opacity = 1;
	element.style.pointerEvents = '';
};
