import { formValidation, plugins, utils } from '@form-validation/bundle/popular';
import { sl_SI } from '@form-validation/locales/sl_SI';

const vatValidator = () => ({
	validate: (input) => {
		let error = {
			valid: true,
		};

		const value = input.value.replace(/SI|si|\s/g, '');
		if (value.length !== 8) {
			error = {
				valid: false,
				message: input.element.dataset.invalidErrorMessage,
			};
		}
		let suma = 0;
		for (let i = 0; i < 7; i++) {
			suma += parseInt(value[i]) * (8 - i);
		}
		const controlDigit = (11 - (suma % 11)) % 10;
		if (parseInt(value[7]) !== controlDigit) {
			error = {
				valid: false,
				message: input.element.dataset.invalidErrorMessage,
			};
		}

		return error;
	},
});

const postcodeValidator = () => ({
	validate: (input) => {
		const { error } = input.element.dataset;

		return {
			valid: error !== 'true',
		};
	},
});

const countryValidator = () => ({
	validate: (input) => {
		const isValid = input.elements.find((element) => element.checked);
		const dropdownContainer = input.element.closest('.dropdown__body')?.querySelector('.dropdown__placeholder');
		dropdownContainer.classList.toggle('dropdown__placeholder__is-validation-failed', !isValid);

		return {
			valid: isValid,
		};
	},
});

const ratingValidator = () => ({
	validate: () => {
		const ratingCheckbox = document.querySelector('.rating-modal__stars-input:checked');
		const parent = document.querySelector('.rating-modal__rate');

		return {
			valid: ratingCheckbox,
			message: parent?.dataset?.errorMessage,
		};
	},
});

const telephoneValidator = () => ({
	validate: (input) => {
		const regexString = /^\+?\d+([-\s]?\d+)*$/;
		return {
			valid: regexString.test(input.value),
			message: input.element?.dataset?.phoneErrorMessage || '',
		};
	},
});

const handleForm = (form) => {
	const fv = formValidation(form, {
		locale: 'sl_SI',
		fields: {},
		localization: sl_SI,
		plugins: {
			message: new plugins.Message({
				container: (field, element) => element.closest('.mod-input,.checkbox,.upload-box, .dropdown__body'),
			}),
			declarative: new plugins.Declarative({
				html5Input: true,
			}),
			trigger: new plugins.Trigger({
				event: 'submit',
			}),
			excluded: new plugins.Excluded({}),
		},
	})
		.registerValidator('validateVat', vatValidator)
		.registerValidator('validateRating', ratingValidator)
		.registerValidator('validatePostcode', postcodeValidator)
		.registerValidator('validateCountry', countryValidator)
		.registerValidator('validatePhone', telephoneValidator)
		.on('core.element.validated', (e) => {
			if (e.element.hasAttribute('required')) {
				const value = fv.getElementValue(e.field, e.element);
				const container = utils.closest(e.element, '.mod-input, .checkbox, .upload-box');
				utils.classSet(container, {
					'is-validation-failed': value === '' || !e.valid,
				});
			}
		});

	return fv;
};

export default handleForm;
