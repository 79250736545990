// **************************************************
// User profile accordion
// **************************************************
import { attachEvent, euro, scrollToElement, scrollToTop, slideToggle } from '../../../../js/default';
import { request } from '../../../../js/api.js';
import { changeValue } from '../../atoms/dropdown/dropdown.js';

const openOrderAccordion = (element) => {
	const parent = element.closest('.user-profile__accordion');
	const accordionContent = parent.querySelector('.user-profile__accordion-content');
	parent.classList.toggle('is-open');
	slideToggle(accordionContent, 300, 'block');
};

attachEvent('.user-profile__accordion-title', 'click', (ev) => {
	openOrderAccordion(ev.target);
	ev.preventDefault();
});

const params = new URL(document.location.toString()).searchParams;
let urlPayerId = params.get('payer') || null;
let urlOrderId = params.get('order') || null;

window.addEventListener('DOMContentLoaded', () => {
	const userProfile = document.querySelector('.user-profile__body');
	if (!userProfile) {
		return;
	}

	const payerDropdown = document.querySelector('.dropdown__list-inner');
	if (!payerDropdown) {
		return;
	}

	const updatePayerDropdown = () => {
		setTimeout(async () => {
			const payerChoice = document.querySelector('.dropdown__placeholder-text');

			const payerData = JSON.parse(payerChoice.dataset.payer);
			const payerId = payerChoice.dataset.ehrm.split('_')[1];
			payerData.installments = payerChoice.dataset.installments === 'true' ? 'Da' : 'Ne';

			const container = payerDropdown.closest('.user-profile__item');

			Object.keys(payerData).forEach((key) => {
				const field = container.querySelector(`.information-box__item-${key}`);
				if (field) {
					if (key === 'kraj') {
						field.textContent = `${payerData.postna} ${payerData[key]}`;
					} else {
						field.textContent = payerData[key];
					}
				}
			});

			container.querySelector('.bonus-applied__amount').textContent = euro.format(payerData.ece_bonus || 0);

			const orderListContainer = document.querySelector('.user-profile__order-list');
			const orderList = await request(`${window.location.origin}/api/v1/profile/orders?id=${payerId}`);

			orderListContainer.innerHTML = orderList.section;

			if (urlOrderId) {
				const orderElement = document.querySelector(`.product-preview[data-order="${urlOrderId}"]`);
				const parent = orderElement.closest('.user-profile__accordion ').querySelector('.user-profile__accordion-title');
				openOrderAccordion(parent);
				scrollToTop(orderElement);
			}
		}, 0);
	};

	if (urlPayerId && urlOrderId) {
		const selectedPayer = document
			.querySelector(`input[id="${urlPayerId}"]`)
			.closest('.dropdown__list-item')
			.querySelector('.dropdown__list-option');
		const placeholder = document.querySelector('.dropdown__placeholder-text');
		changeValue(selectedPayer, placeholder);
		updatePayerDropdown();
	}

	payerDropdown.addEventListener('click', () => {
		if (urlOrderId && urlPayerId) {
			const url = new URL(window.location.href);
			url.search = '';
			history.replaceState(null, '', url.pathname + url.hash);
			urlOrderId = null;
			urlPayerId = null;
		}
		updatePayerDropdown();
	});
});
